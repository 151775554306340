<div class="flex flex-1 flex-col px-5">

  <div class="mb-2 flex justify-between">
    <div class="flex flex-col">
      <h1 class="font-black text-[20px] text-black">Canal - {{channel?.name}}</h1>
      <h6 class="text-zinc-400 font-light text-sm mt-2">Visualização do Canal - {{getTranslatedTypeChannel(channel?.type)}}</h6>
    </div>
  </div>
  <hr />

  <!-- <channel-config-component *ngIf="!!channel" [channel]="channel"></channel-config-component> -->


  <section *ngIf="channel" class="w-full flex flex-row flex-wrap mt-[24px]">

    <input id="tab-one" type="radio" name="tabs" class="peer/tab-one opacity-0 absolute" checked />
    <label for="tab-one" (click)="changeTab('tab-one')"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-one:border-b-2 peer-checked/tab-one:border-[#4213F6] peer-checked/tab-one:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Dashboard
    </label>

    <input id="tab-two" type="radio" name="tabs" class="peer/tab-two opacity-0 absolute" />
    <label for="tab-two" (click)="changeTab('tab-two')"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-two:border-b-2 peer-checked/tab-two:border-[#4213F6] peer-checked/tab-two:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Configurações
    </label>

    <input id="tab-three" type="radio" name="tabs" class="peer/tab-three opacity-0 absolute" />
    <label for="tab-three" (click)="changeTab('tab-three')"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-three:border-b-2 peer-checked/tab-three:border-[#4213F6] peer-checked/tab-three:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Auto Atendimento (Chatbot)
    </label>

    <input id="tab-four" type="radio" name="tabs" class="peer/tab-four opacity-0 absolute" />
    <label for="tab-four" (click)="changeTab('tab-four')"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-four:border-b-2 peer-checked/tab-four:border-[#4213F6] peer-checked/tab-four:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Respostas Automáticas
    </label>

    <input *ngIf="env !== 'prod' && channel?.type === 'CLOUD_API'" id="tab-five" type="radio" name="tabs"
      class="peer/tab-five opacity-0 absolute" />
    <label *ngIf="env !== 'prod' && channel?.type === 'CLOUD_API'" for="tab-five" (click)="changeTab('tab-five')"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-five:border-b-2 peer-checked/tab-five:border-[#4213F6] peer-checked/tab-five:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Templates
    </label>

    <input id="tab-six" type="radio" name="tabs" class="peer/tab-six opacity-0 absolute" />
    <label for="tab-six" (click)="changeTab('tab-six')"
      class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-six:border-b-2 peer-checked/tab-six:border-[#4213F6] peer-checked/tab-six:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
      Módulos
    </label>

    <!-- First tab -->
    <div class="hidden peer-checked/tab-one:flex w-full">
      <div class="flex flex-1 flex-col mt-4">
        <channel-config-component  *ngIf="selectedTab === 'tab-one'" [channel]="channel"></channel-config-component>
      </div>
    </div>

    <!-- Second tab -->
    <div class="hidden peer-checked/tab-two:block w-full">
      <div class="flex flex-1 flex-col mt-4">
        <settings-attendance-component *ngIf="selectedTab === 'tab-two'"
          [channel]="channel"></settings-attendance-component>
      </div>
    </div>

    <!-- Third tab -->
    <div class="hidden peer-checked/tab-three:block w-full">
      <div class="flex flex-1 flex-col mt-4">
        <chatbot-component *ngIf="selectedTab === 'tab-three'" [channel]="channel"></chatbot-component>
      </div>
    </div>

    <!-- Fourth tab -->
    <div class="hidden peer-checked/tab-four:block w-full">
      <div class="flex flex-1 flex-col mt-4">
        <response-automatic-component *ngIf="selectedTab === 'tab-four'"
          [channel]="channel"></response-automatic-component>
      </div>
    </div>

    <!-- fifth tab -->
    <div class="hidden peer-checked/tab-five:block w-full">
      <div class="flex flex-1 flex-col mt-4">
        <setting-template-list-component [channel]="channel"
          *ngIf="selectedTab === 'tab-five' && channel?.type === 'CLOUD_API'" />
      </div>
    </div>

    <!-- Sixth tab -->
    <div class="hidden peer-checked/tab-six:block w-full">
      <div class="flex flex-1 flex-col mt-4">
        <settings-module-component *ngIf="selectedTab === 'tab-six'" [channel]="channel"></settings-module-component>
      </div>
    </div>

  </section>

</div>
