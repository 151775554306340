<div class="flex flex-1 flex-col px-5">
  <div class="container-full flex flex-col justify-content-center">
    <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} Canal</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      {{ isNew ? 'Preencha os dados e crie um novo canal' :
      'Preencha os dados para alterar seu canal existente'}}</h6>
  </div>
  <hr />
  <form [formGroup]="formGroup">
    <div class="flex flex-col mt-[15px]">
      <label class="font-semibold text-black">Selecione um canal *</label>
      <mat-select class="input-field" formControlName="type" placeholder="Selecione"
        (selectionChange)="valueChangeType()">
        <mat-option [value]="cloudAPI" [disabled]="checkIfExceded(cloudAPI)">
          Cloud API
          <small>{{ checkIfExceded(cloudAPI) ? '(Número de canais excedido)' : ''}}</small>
        </mat-option>
        <mat-option [value]="evolutionAPI" [disabled]="checkIfExceded(evolutionAPI)">
          Business
          <small>{{ checkIfExceded(evolutionAPI) ? '(Número de canais excedido)' : ''}}</small>
        </mat-option>
        <mat-option [value]="evolutionGOAPI" [disabled]="checkIfExceded(evolutionGOAPI)">
          Business PRO
          <small>{{ checkIfExceded(evolutionGOAPI) ? '(Número de canais excedido)' : ''}}</small>
        </mat-option>
      </mat-select>
      <field-error-component [field]="'type'" [label]="'Canal'" [formGroup]="formGroup"></field-error-component>
    </div>
    @if (formGroup.get('type')?.value) {
    <div class="flex flex-col">
      <label class="font-semibold text-black mt-2">Nome *</label>
      <input maxlength="100" formControlName="name" class="input-field" placeholder="Digite o nome do seu canal...">
      <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup"></field-error-component>
    </div>
    <hr class="mb-[15px] mt-[15px]" />
    @if (formGroup.get('type')?.value === 'CLOUD_API') {
    <div class="flex gap-4 w-full">
      <div class="w-[50%] shadow-global rounded-lg">
        <mat-card class="flex items-center justify-center">
          <mat-card-header>
            <mat-card-title>Começar com a API Business do WhatsApp</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              API Business do WhatsApp
              O WhatsApp é um dos aplicativos de comunicação mais populares do mundo. O WhatsApp é uma empresa do
              Facebook e requer uma conta do Facebook e o Facebook Business Manager para configurar
              <br />
              <br />
              Você será direcionado para o Facebook Business Manager. Conclua todas as etapas no pop-up para criar
              seu canal do WhatsApp Business.
            </p>
          </mat-card-content>
          <mat-card-footer class="mt-[18px]">
            <app-whatsapp-signup [disabledButton]="!getName()" [nameChannel]="getName()" (onSave)="goBack()"
              (onSave)="goBack()"></app-whatsapp-signup> ou <a style="text-decoration: underline !important;"
              class="text-[#4213F6] mt-[15px] cursor-pointer" (click)="checkedManualFulfil()">preencher
              manualmente</a>
          </mat-card-footer>
        </mat-card>
      </div>
      <div class="w-[50%] shadow-global rounded-lg">
        <mat-card class="w-full h-full p-4" *ngIf="manualFulfil">
          <div class="p-2 flex flex-col mt-[15px]" formGroupName="credentials">
            <div class="flex flex-col">
              <label class="font-semibold text-black">Token *</label>
              <input formControlName="token" class="input-field" placeholder="Digite o seu token...">
              <field-error-component [field]="'credentials.token'" [label]="'Token'"
                [formGroup]="formGroup"></field-error-component>
            </div>
            <div class="flex w-full mt-[15px] gap-2" id="form-cloud">
              <div class="flex w-[50%] flex-col" id="inputs-form-cloud">
                <label class="font-semibold text-black">Phone Number ID *</label>
                <input maxlength="100" formControlName="phoneNumberID" class="input-field"
                  placeholder="Digite o Phone Number ID...">
                <field-error-component [field]="'credentials.phoneNumberID'" [label]="'Phone Number ID'"
                  [formGroup]="formGroup"></field-error-component>
              </div>
              <div class="flex w-[50%] flex-col" id="inputs-form-cloud">
                <label class="font-semibold text-black">WhatsApp Business Account ID *</label>
                <input maxlength="100" formControlName="whatsAppBusinessAccountID" class="input-field"
                  placeholder="Digite o WhatsApp Business ID...">
                <field-error-component [field]="'credentials.whatsAppBusinessAccountID'"
                  [label]="'WhatsApp Business Account ID'" [formGroup]="formGroup"></field-error-component>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card class="w-full h-full" *ngIf="!manualFulfil">
          <mat-card-header class="flex items-center justify-center">
            <mat-card-title>O que você precisa?</mat-card-title>
          </mat-card-header>
          <mat-card-content class="flex items-center justify-center mt-[15px]">
            <p>
              1. Acesso ao Facebook Business Manager
              <br />
              2. Um número de telefone viável para o WhatsApp
              <br />
              3. Endereço legal e detalhes da sua empresa
              <br />
              4. Selecione os números que você deseja conectar
              <br />
              5. Verifique seu Facebook Business
              <br />
            </p>
          </mat-card-content>
          <mat-card-footer class="flex items-center justify-center mt-[15px]">
            <mat-chip-set aria-label="Chihuahua traits">
              <mat-chip class="!bg-green-500">
                <span class="!text-white">WhatsApp</span>
              </mat-chip>
              <mat-chip class="!bg-blue-500">
                <span class="!text-white">Meta</span>
              </mat-chip>
              <mat-chip class="!bg-[#4213F6]">
                <span class="!text-white">Trendmulti</span>
              </mat-chip>
            </mat-chip-set>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
    }
    }
  </form>

  <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
      (click)="goBack()">Cancelar</button>
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal  bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      (click)="save()" [disabled]="!formGroup.valid">
      {{ isNew ? 'Salvar' : 'Alterar' }}
    </button>
  </div>

</div>