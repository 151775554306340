import { AfterContentInit, Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LegendPosition } from '@swimlane/ngx-charts';
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum, ConfirmationComponent, Contact, ContactListModalComponent, ContactService } from 'lib-trend-core';
import { BehaviorSubject, concatMap, filter, map, mergeMap, of, takeUntil, tap, timer } from 'rxjs';
import { ChannelEditComponent } from '../channel-edit/channel-edit.component';

@Component({
  selector: 'channel-config-component',
  templateUrl: './channel-config.component.html',
  styleUrls: ['./channel-config.component.scss']
})
export class ChannelConfigComponent extends AbstractComponent implements OnInit, AfterContentInit {

  @Input('channel') channel: Channel;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  INSTANCE_CODE_DATA: string;
  countContacts: number = 0;
  countChats: number = 0;
  countMessages: number = 0;

  dataChartOfChannel: Array<{ name: string, series: Array<{ name: string, value: number }> }>;
  totalContacts: string;
  totalMessages: string;
  totalAttendances: string;

  legendPosition: LegendPosition = LegendPosition.Right;
  customColors: Array<{ name: string, value: string }> = [
    { name: 'Mensagens enviadas', value: '#4213F6' },
    { name: 'Mensagens recebidas', value: '#8F8CFF' },
  ]

  listContact: Array<Contact>;

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    private dialog: MatDialog,
    private contactService: ContactService,
    private dialogContact: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getChannelWithMetrics();
  }

  ngAfterContentInit(): void {
    this.init();
  }

  // Method for fetch base64 and with interval of 20 seconds
  private init(): void {
    if (!!this.channel && this.channel.type !== ChannelTypeEnum.CLOUD_API) {
      this.loadingSpinnerSubject.next(true);
      timer(0, 30000).pipe(
        mergeMap(() => this.channelService.syncInstanceByChannel(this.channel._id)),
        concatMap((channel) => {
          this.channel = channel;
          if (channel.type === ChannelTypeEnum.EVOLUTION_API) {
            this.countContacts = this.channel?.metadata?.['_count']?.['Contact'] ?? 0;
            this.countChats = this.channel?.metadata?.['_count']?.['Chat'] ?? 0;
            this.countMessages = this.channel?.metadata?.['_count']?.['Message'] ?? 0;
          }
          if (this.channel.connected) {
            return of(null);
          } else {
            return this.channelService.getInstanceConnect(this.channel._id)
              .pipe(
                map((value) => {
                  if (channel.type === ChannelTypeEnum.EVOLUTION_API) {
                    this.INSTANCE_CODE_DATA = value['code'];
                  } else if (channel.type === ChannelTypeEnum.EVOLUTION_GO_API) {
                    this.INSTANCE_CODE_DATA = value['data']['Code'];
                  }
                }),
                takeUntil(this.destroy$),
              );
          }
        }),
        tap(() => this.loadingSpinnerSubject.next(false)),
        takeUntil(this.destroy$),
      ).subscribe({
        complete: () => this.loadingSpinnerSubject.next(false)
      });
    }
  }

  sync(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.syncInstanceByChannel(this.channel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (channel: Channel) => this.channel = channel,
        error: (err) => this.alertService.error(err.error.message),
        complete: (() => this.loadingSpinnerSubject.next(false))
      });
  }

  syncContacts(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.syncContactsByChannel(this.channel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (channel: Channel) => {
          this.channel = channel;
          this.alertService.success('Contatos sincronizados com sucesso!');
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: (() => this.loadingSpinnerSubject.next(false))
      });
  }


  restart(): void {
    this.loadingSpinnerSubject.next(true);
    this.channelService.restartInstanceByChannel(this.channel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (channel: Channel) => this.channel = channel,
        error: (err) => this.alertService.error(err.error.message),
        complete: (() => this.loadingSpinnerSubject.next(false))
      });
  }

  disconnect(): void {
    this.loadingSpinnerSubject.next(true);
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.channelService.disconnectInstanceByChannel(this.channel._id).subscribe({
          next: (channel: Channel) => this.channel = channel,
          error: (err) => {
            this.alertService.error(err.error.message);
            this.loadingSpinnerSubject.next(false);
          },
          complete: (() => this.loadingSpinnerSubject.next(false))
        });
      }
    });
  }

  private getChannelWithMetrics(): void {
    this.channelService.getByIdAndMetrics(this.channel._id, this.channel.identifier)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value) => {
          this.dataChartOfChannel = [
            {
              name: 'Mensagens enviadas',
              series: [
                {
                  name: 'Mensagens enviadas',
                  value: value.find((value) => value).sentMessages ?? 0,
                },
              ],
            },
            {
              name: 'Mensagens recebidas',
              series: [
                {
                  name: 'Mensagens recebidas',
                  value: value.find((value) => value).receivedMessages ?? 0,
                },
              ],
            },
          ];

          this.totalContacts = value.find((value) => value).totalContacts.toString() ?? '0';
          this.totalMessages = value.find((value) => value).totalMessages.toString() ?? '0';
          this.totalAttendances = value.find((value) => value).totalAttendance.toString() ?? '0';
        },
        error: (err) => this.alertService.error(err.error.message),
      });
  }

  edit(item: Channel): void {
    const dialogRef = this.dialog.open(ChannelEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  delete(channel: Channel) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.channelService.delete(channel._id).subscribe({
          next: () => {
            this.alertService.success();
            this.router.navigate(['/channel']);
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  blockOrUnblockContact(): void {
    const dialogRef = this.dialogContact.open(ContactListModalComponent, {
      maxHeight: '1200px',
      minHeight: '900px',
      width: '1200px',
      data: { channel: this.channel, block: true },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (!!value) {
        if (value.block) {
          this.contactService.blockContact(value.contact, this.channel).subscribe({
            next: () => this.alertService.success('Contato bloqueado com sucesso.'),
          });
        } else {
          this.contactService.unblockContact(value.contact, this.channel).subscribe({
            next: () => this.alertService.success('Contato desbloqueado com sucesso.'),
          });
        }
      }
    });
  }

}
