import { Component, Inject, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'channel-edit-component',
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss']
})
export class ChannelEditComponent extends AbstractComponent {

  channel: Channel;
  channels: Array<Channel> = new Array<Channel>();

  cloudAPI: ChannelTypeEnum = ChannelTypeEnum.CLOUD_API;
  evolutionAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_API;
  evolutionGOAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_GO_API;

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    public dialogRef: MatDialogRef<ChannelEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Channel,
  ) {
    super(injector);
    this.channel = { credentials: {} } as Channel;
  }

  ngOnInit() {
    this.getChannelById(this.data?._id);
    this.createForm();
  }

  private createForm(): void {
    if (this.data?.type === ChannelTypeEnum.CLOUD_API) {
      this.formGroup = new FormGroup({
        name: new FormControl(this.channel.name, [Validators.required]),
        type: new FormControl(this.channel.type, [Validators.required]),
        integration: new FormControl(true),
        credentials: new FormGroup({
          token: new FormControl(this.channel.credentials['token']),
          phoneNumberID: new FormControl(this.channel.credentials['phoneNumberID']),
          whatsAppBusinessAccountID: new FormControl(this.channel.credentials['whatsAppBusinessAccountID']),
        }),
      });
    } else {
      this.formGroup = new FormGroup({
        name: new FormControl(this.channel.name, [Validators.required]),
        type: new FormControl(this.channel.type, [Validators.required]),
        integration: new FormControl(true),
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private getChannelById(idChannel: string) {
    this.channelService.getById(idChannel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (channel: Channel) => {
          this.channel = channel;
          this.formGroup.patchValue(this.channel);
          this.formGroup.get('integration').setValue(false);
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }

    this.channelService.update(this.channel._id, this.formGroup.value).subscribe({
      next: () => {
        this.alertService.success('Canal atualizado com sucesso!');
        this.goBack();
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  showSaveButton() {
    return this.isAdmin() && (!this.formGroup.get('integration').value || !this.isCloudAPI());
  }

  getName() {
    return this.formGroup.get('name').value;
  }

  private isCloudAPI() {
    return this.formGroup.get('type').value === ChannelTypeEnum.CLOUD_API;
  }
}
