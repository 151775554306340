import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Channel, ChannelService } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'channel-view-component',
  templateUrl: 'channel-view.component.html',
  styleUrls: ['./channel-view.component.scss']
})
export class ChannelViewComponent extends AbstractComponent implements OnInit {

  channel: Channel = undefined;

  selectedTab: string = 'tab-one';

  constructor(
    public injector: Injector,
    private channelService: ChannelService,
  ) {
    super(injector);
  }

  ngOnInit() {
    const id = super.getParam('idChannel');
    if (!!id) {
      this.channelService.getById(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (channel) => {
            this.channel = channel;
          },
          error: (err) => this.alertService.error(err.error.message)
        });
    }
  }

  changeTab(tab: string): void {
    this.selectedTab = tab;
  }

}
