import { Component, Injector, Input, OnInit } from "@angular/core";
import { TemplateMessageObjectPager } from "@trendbuild/trend-cloud-api";
import { AbstractComponent, Channel, ChannelService, Pager, WaTemplateResponse } from "lib-trend-core";
import { Observable, takeUntil } from "rxjs";

@Component({
  selector: 'setting-template-list-component',
  templateUrl: './setting-template-list.component.html',
})

export class SettingTemplateList extends AbstractComponent implements OnInit {

  constructor(injector: Injector, private channelService: ChannelService) {
    super(injector);
  }

  @Input() channel: Channel

  templates: Partial<WaTemplateResponse>[] = [];
  pager: Pager<Partial<WaTemplateResponse>> = new Pager<Partial<WaTemplateResponse>>({ perPage: 19 });
  listObservable: Observable<Pager<Partial<WaTemplateResponse>>>;
  previewArray: Array<{ text: string; type: string, example?: object }>

  ngOnInit(): void {
    this.pager = {
      page: 1,
      perPage: 10,
      previousPage: 0,
      total: 0,
      list: []
    };

    this.getList()
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  dictionaryLanguage(language: string) {
    const dictionary = {
      "pt_BR": "Português",
      "pt_PT": "Português",
      "en_US": "Inglês",
      "en": "Inglês",
    }

    return dictionary[language]
  }

  dictionaryStatus(status: string) {
    const dictionary = {
      "APPROVED": "Aprovado",
    }

    return dictionary[status]
  }

  dictionaryCategory(category: string) {
    const dictionary = {
      "MARKETING": "Marketing",
      "UTILITY": "Utilitário"
    }

    return dictionary[category]
  }

  async getList() {

    this.channelService.getTemplatesByChannel(this.channel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (templateMessageObjectPager: TemplateMessageObjectPager) => {

          const allTemplates = templateMessageObjectPager.data || [];

          this.pager.total = allTemplates.length;

          const start = (this.pager.page - 1) * this.pager.perPage;
          const end = start + this.pager.perPage;

          this.templates = allTemplates.slice(start, end);

          this.previewArray = this.templates.map(template => template.components.filter(component => component.type === 'BODY')[0])
        }
      });
  }

  showEditTemplate(idTemplate: string) {
  }

  deleteTemplate(idTemplate: string) {
  }
}